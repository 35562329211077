import { urlJoin } from "../../workarounds/url-join";
import { getMicroserviceCoreAPIClient } from "..";
import { createTypedEventEmitter } from '../../eventemitter';
import { Permission, UserInfo } from "../../types/authentication";
import axios from "axios";
import { createLogger } from "../../base-logic/Logging";
import { MSCCommon } from "../../impl/shared";

const authLogger = createLogger({
  sourceNamePath: ['microservice-client', 'authentication']
});

type User = any; // Stand-in until API stabilization

type AuthState = {
  state: 'authenticated',
  user: User
} | {
  state: 'unauthenticated'
} | {
  state: 'loading'
};


let AUTH_TOKEN: string | undefined = undefined;

function updateAuthToken(newToken: string) {
  console.log(`UpdateV3`);
  AUTH_TOKEN = newToken;
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
}


(() => {
  const logger = authLogger.createSubLogger(['init']);
  const fetch = globalThis.fetch;

  (globalThis as any).auth = {
    clear: () => {
      AUTH_TOKEN = undefined;
    }
  };

  function getCookie(name: string) {
    if (typeof globalThis.window === 'undefined') {
      return AUTH_TOKEN; // NodeJS, no document, no cookies
    }
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()!.split(';').shift();
  }

  const token = getCookie('auth-token');
  if (token) {
    logger.log(`Resuming auth token from cookie`);
    updateAuthToken(token);
  }
  else {
    logger.log(`Auth-token cookie not found`);
  }


  globalThis.fetch = (url, options) => {
    options = options || {};
    options.credentials = 'include';
    const headers: Headers = new Headers(options.headers);
    options.headers = headers;
    {
      const token = AUTH_TOKEN;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }
    return fetch(url, options);
  };
})();

const getMicroservice = async () => {
  const client = await getMicroserviceCoreAPIClient();
  const microservices = await client.serviceDiscovery.getAvailableServicesForCapability(['authentication']);
  if (microservices.length < 1) {
    throw new Error(`No service available to handle capability 'authentication'`);
  }
  return microservices[0];
};




export async function authenticationServicePlugin() {
  const eventEmitter = createTypedEventEmitter<{
    onStateChange: AuthState,
    userInfoUpdate: UserInfo | undefined
  }>();



  const isFeatureAvailable = async () => {
    const client = await getMicroserviceCoreAPIClient();
    const microservices = await client.serviceDiscovery.getAvailableServicesForCapability(['authentication']);
    if (microservices.length < 1) {
      return false;
    }
    return true;
  }

  const login = async (username: string, password: string) => {
    const logger = authLogger.createSubLogger(['login']);
    logger.log('Starting authentication login flow...');
    eventEmitter.emit('onStateChange', {
      state: 'loading'
    });
    const microservice = await getMicroservice();
    const loginURL = urlJoin(microservice.rootURI, "login");
    logger.log(`Attempting authentication with server @ (${loginURL.toString()})`);
    const init: RequestInit = {
      method: 'POST',
      body: JSON.stringify({
        username,
        password
      }),
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
    };
    try {
      const response = await fetch(loginURL, init);
      if (response.status === 403) {
        eventEmitter.emit('onStateChange', {
          state: 'authenticated',
          user: await getUserInfo()
        });
        logger.info(`Authenticated`);
        return;
      }
      if (!response.ok) {
        logger.error(`Server responded with an error`);
        throw new Error(`Failed to login`);
      }

      AUTH_TOKEN = await response.text();
      updateAuthToken(AUTH_TOKEN);

      eventEmitter.emit('onStateChange', {
        state: 'authenticated',
        user: await getUserInfo()
      });

    } catch (e) {
      logger.error(`Authentication failed: fetch potentially threw an exception`, e);
      eventEmitter.emit('onStateChange', {
        state: 'unauthenticated'
      });
      throw e;
    }
  };

  const getUserInfo = async () => {
    const microservice = await getMicroservice();
    const targetURL = urlJoin(microservice.rootURI, "user");
    const fetchResult = await fetch(targetURL);
    if (!fetchResult.ok) {
      eventEmitter.emit('userInfoUpdate', undefined);
      const errorBody = await fetchResult.json();
      throw new Error(errorBody.message);
    }
    const user: UserInfo = await fetchResult.json();
    eventEmitter.emit('userInfoUpdate', user);
    return user;
  }

  const isLoggedIn = async () => {
    try {
      const user = await getUserInfo();
      eventEmitter.emit('onStateChange', {
        state: 'authenticated',
        user: user
      });
      return true;
    }
    catch {
      return false;
    }
  };

  let userPermissionsStore: any | undefined = undefined;

  eventEmitter.on('onStateChange', () => userPermissionsStore = undefined);

  const getUserPermissions = async (): Promise<Permission[]> => {
    if (userPermissionsStore) return userPermissionsStore;
    const microservice = await getMicroservice();
    const targetURL = urlJoin(microservice.rootURI, "permissions");
    const response = await fetch(targetURL);
    const responseBody: Permission[] = await response.json();
    userPermissionsStore = responseBody;
    return responseBody;
  };

  const getEventEmitter = () => eventEmitter;

  async function authenticationServicePluginMicroserviceAPI() {
    const AUTH_TTL = 1000 * 60 * 5;

    const sanitizeToken = (token: string) => {
      return token.replaceAll("Bearer ", "");
    }

    const privateUserInfo: {
      [key: string]: UserInfo | undefined;
    } = {};
    const getUserInformation = async (token: string) => {
      token = sanitizeToken(token);
      if (privateUserInfo[token] !== undefined) {
        return privateUserInfo[token];
      }
      const microservice = await getMicroservice();
      const baseTargetURL = urlJoin(microservice.rootURI, "private", "getUserInformation");
      const fullTargetURL = baseTargetURL + `?token=${token}`;
      const response = axios.get(fullTargetURL);
      const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
      privateUserInfo[token] = responseBody;
      setTimeout(() => {
        privateUserInfo[token] = undefined;
      }, AUTH_TTL);
      return responseBody;
    };

    const userPermissions: {
      [key: string]: Permission[] | undefined
    } = {};

    const getUserPermissionsRestricted = async (token: string) => {
      token = sanitizeToken(token);
      if (userPermissions[token] !== undefined) {
        return userPermissions[token];
      }
      const microservice = await getMicroservice();
      const baseTargetURL = urlJoin(microservice.rootURI, "private", "getUserPermissions");
      const fullTargetURL = baseTargetURL + `?token=${token}`;
      const response = axios.get(fullTargetURL);
      const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
      userPermissions[token] = responseBody;
      setTimeout(() => {
        userPermissions[token] = undefined;
      }, AUTH_TTL);
      return responseBody;
    };

    return {
      getUserInformation,
      getUserPermissions: getUserPermissionsRestricted
    }
  }

  setTimeout(isLoggedIn, 1500); // Immediately check authentication status

  eventEmitter.on('userInfoUpdate', async data => {
    const logger = authLogger.createSubLogger([`eventEmitterCallback['userInfoUpdate']`]);
    if (data) {
      const permissions = await getUserPermissions();
      logger.info(`Logged in as '${data.username}'. Permissions: `, permissions.map(x => x.permissionID));
    }
    else {
      logger.info('No logged in user');
    }
  });

  return {
    isFeatureAvailable,
    login,
    isLoggedIn,
    getUserInfo,
    getUserPermissions,
    getEventEmitter,
    microserviceAPI: await authenticationServicePluginMicroserviceAPI()
  }
}
