export function createOverrideableMethodManager<TSchema extends {}>() {
  const functions: {
    [key in keyof TSchema]: (TSchema[key])[] | undefined
  } = {} as any;

  function getFunctionHierarchy<TKey extends keyof TSchema>(key: TKey) {
    if (functions[key] === undefined) {
      functions[key] = [];
    }
    return functions[key]!;
  }

  function generateCaller<TKey extends keyof TSchema>(key: TKey) {
    return ((...args: any[]) => {
      const handlers = getFunctionHierarchy(key);
      if (handlers.length < 1) {
        throw new Error(`Cannot invoke method '${String(key)}': no handler installed`);
      }
      const target: (...args: unknown[]) => unknown = handlers[handlers.length - 1] as any;
      return target(...args);
    }) as TSchema[TKey];
  }

  function registerOverridableMethod<TKey extends keyof TSchema>(key: TKey, method: TSchema[TKey]) {
    getFunctionHierarchy(key).push(method);
    return generateCaller(key);
  }

  function overrideMethod<TKey extends keyof TSchema>(key: TKey, method: TSchema[TKey]) {
    const handlers = getFunctionHierarchy(key);
    if (handlers.length < 1) {
      throw new Error(`Cannot override method '${String(key)}': the base method has not been registered`);
    }
    handlers.push(method);
    return generateCaller(key);
  }

  function getMethod<TKey extends keyof TSchema>(key: TKey): TSchema[TKey] {
    return generateCaller(key);
  }

  return {
    registerOverridableMethod,
    overrideMethod,
    getMethod
  };
}

const x = createOverrideableMethodManager<{
  test: (name: string) => string
}>();

x.registerOverridableMethod('test', name => {
  return "hi";
});
