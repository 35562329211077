import { SensorIdentifier } from "../../types/sensorIdentifier";
import { getMicroserviceCoreAPIClient } from "..";
import {
  DataSource,
  GraphPackage,
  SensorDataRequestDocument,
  SensorInstance,
  SensorStatusChunkMap,
} from "../../types/sensorData";
import { Mutex } from "async-mutex";
import { urlJoin } from "../../workarounds/url-join";
import { createKeyedMutexAccessor } from "../../base-logic/keyedMutexAccessor";
import axios from "axios";
import { MSCCommon } from "../../impl/shared";

const CONSTANTS = {
  FEATURES: {
    GENERIC: "sensor-data",
    LOCATION: "sensor-data",
    STATUS: "sensor-status",
    LATEST_READING_DATE: "sensor-data-latest-reading-date",
  },
};

export type CooperativeGraphingValueFormatterContext = {
  [key: string]: unknown
};

export type CooperativeGraphingValueFormatterContextHydrated = CooperativeGraphingValueFormatterContext & {
  targetFormatter: string
};

export type CooperativeGraphingValueFormatter = {
  contextualizer: (context: CooperativeGraphingValueFormatterContextHydrated) => ((...args: any[]) => any[]) | undefined,
};
export type CooperativeGraphingPipelineElement = {
  target: string,
  context: CooperativeGraphingValueFormatterContext
};
export type CooperativeGraphingPipeline = CooperativeGraphingPipelineElement[];

export async function sensorDataServicePlugin() {
  let dataSourceCache: DataSource[] | undefined = undefined;
  const dataSourceCacheMutex = new Mutex();

  const getDataSources = async () => {
    return await dataSourceCacheMutex.runExclusive(async () => {
      if (dataSourceCache !== undefined) {
        console.log(`Cached`, dataSourceCache);
        return await Promise.resolve(dataSourceCache);
      }
      const client = await getMicroserviceCoreAPIClient();
      const microservice =
        await client.serviceDiscovery.getAvailableServiceInstanceForCapability([
          CONSTANTS.FEATURES.GENERIC,
        ]);
      const requestURI = urlJoin(microservice.rootURI, "sources");
      const response = axios.get(requestURI.toString());
      const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
      dataSourceCache = responseBody;
      return responseBody as DataSource[];
    });
  };

  const getSensorInstances = (() => {
    const cache = createKeyedMutexAccessor<string, SensorInstance[] | null>({}, () => null);

    const operator = async (_targetCollection: DataSource | string) => {
      if (typeof _targetCollection !== "string") {
        _targetCollection = _targetCollection.data_source_name;
      }
      const targetCollection = _targetCollection as string;

      return await cache.operateOn(targetCollection, async context => {
        if (context.value !== null) {
          return context.value;
        }
        const client = await getMicroserviceCoreAPIClient();
        const microservice =
          await client.serviceDiscovery.getAvailableServiceInstanceForCapability([
            CONSTANTS.FEATURES.GENERIC,
          ]);
        const requestURI = urlJoin(
          microservice.rootURI,
          "source",
          targetCollection,
          "instances",
        );
        const result = axios.get(requestURI.toString());
        const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(result)
        context.value = responseBody;
        return responseBody as SensorInstance[];
      });
    };
    return operator;
  })();

  let latestBulkSensorReadingDates:
    | {
      [key: string]: {
        [key: string]: Date;
      };
    }
    | undefined = undefined;

  // Since this is cached, we need to ensure only one async context can try to fetch it at the same time
  // otherwise multiple invocations will fetch the same data from the server and update the cached value at the same time.
  let bulkReadingDatesMutex = new Mutex();

  const getBulkLatestSensorReadingDates: () => Promise<{
    [key: string]: {
      [key: string]: Date;
    };
  }> = async () => {
    const release = await bulkReadingDatesMutex.acquire();
    try {
      if (latestBulkSensorReadingDates !== undefined) {
        return latestBulkSensorReadingDates;
      }
      const client = await getMicroserviceCoreAPIClient();
      const microservice =
        await client.serviceDiscovery.getAvailableServiceInstanceForCapability([
          CONSTANTS.FEATURES.GENERIC,
        ]);
      const requestURI = urlJoin(
        microservice.rootURI,
        "bulk",
        "latestReadingDates",
      );
      const response = axios.get(requestURI.toString());
      const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
      for (const collectionKey in responseBody) {
        const collectionEntries = responseBody[collectionKey];
        for (const sensorKey in collectionEntries) {
          collectionEntries[sensorKey] = new Date(collectionEntries[sensorKey]);
        }
      }
      latestBulkSensorReadingDates = responseBody;
      setTimeout(
        () => {
          latestBulkSensorReadingDates = undefined;
        },
        1000 * 60 * 5,
      );
      return responseBody;
    } finally {
      release();
    }
  };

  const getDateOfLatestReading = async (
    target: SensorIdentifier | SensorInstance,
  ) => {
    target = (() => {
      // If it's a SensorInstance or SensorIdentifier, get a SensorIdentifier out of it
      if ("instance_unique_identifier" in target) {
        return {
          collection: target.instance_data_source,
          id: target.instance_unique_identifier,
        };
      } else {
        return target;
      }
    })();

    const entries = await getBulkLatestSensorReadingDates();

    return (
      entries[target.collection][target.id] ?? new Date("0001-01-01T00:00:00Z")
    );
  };

  const getSensorData = async (
    request: SensorDataRequestDocument
  ) => {
    const client = await getMicroserviceCoreAPIClient();
    const microservice =
      await client.serviceDiscovery.getAvailableServiceInstanceForCapability([
        CONSTANTS.FEATURES.GENERIC,
      ]);
    const requestURI = urlJoin(microservice.rootURI, "sensor", "data");
    const response = axios.post(requestURI.toString(), JSON.stringify(request), {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
  };

  let sensorPositions: {
    [key: string]: {
      [key: string]: [number, number] | undefined
    }
  } | undefined = {};

  const getSensorPosition = async (target: SensorInstance | { sensorCollection: string, sensorID: string }) => {
    if (sensorPositions === undefined) {
      const client = await getMicroserviceCoreAPIClient();
      const microservice = await client.serviceDiscovery.getAvailableServiceInstanceForCapability([CONSTANTS.FEATURES.LOCATION]);
      const requestURI = urlJoin(microservice.rootURI, "bulk", "sensorPositions");
      const response = axios.get(requestURI.toString());
      const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response, `Failed to fetch sensor position`);
      sensorPositions = responseBody;
    }
    if (sensorPositions === undefined) {
      throw new Error("Impossible");
    }

    const get = async (collection: string, id: string) => {
      if (sensorPositions === undefined) {
        throw new Error(`Sensor position cache data is unavailable`);
      }
      sensorPositions[collection] = sensorPositions[collection] || {};
      return sensorPositions[collection][id];
    }

    if ('sensorCollection' in target) {
      return await get(target.sensorCollection, target.sensorID);
    }
    else {
      return await get(target.instance_data_source, target.instance_unique_identifier);
    }
  };

  let chunks: SensorStatusChunkMap | undefined = undefined;
  let refreshChunks: boolean = true;

  const getAllSensorStatusChunks = async () => {
    if (refreshChunks) {
      const task = (async () => {
        const client = await getMicroserviceCoreAPIClient();
        const microservice = await client.serviceDiscovery.getAvailableServiceInstanceForCapability([CONSTANTS.FEATURES.GENERIC]);
        const requestURI = urlJoin(microservice.rootURI, "bulk", "sensorStatus");
        const response = axios.get(requestURI.toString());
        const responseBody: SensorStatusChunkMap = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
        chunks = responseBody;
        refreshChunks = false;
        setTimeout(() => {
          refreshChunks = true
        }, 5000);
      })();
      if (chunks === undefined) {
        await task;
      }
    }
    return chunks!;
  }

  const getSensorStatusChunks = async (target: SensorInstance | { sensorCollection: string, sensorID: string }) => {
    const chunks = await getAllSensorStatusChunks();

    let realTarget: { sensorCollection: string, sensorID: string };
    if ('sensorCollection' in target) {
      realTarget = target;
    }
    else {
      realTarget = {
        sensorCollection: target.instance_data_source,
        sensorID: target.instance_unique_identifier
      }
    }

    chunks[realTarget.sensorCollection] = chunks[realTarget.sensorCollection] ?? {};
    chunks[realTarget.sensorCollection][realTarget.sensorID] = chunks[realTarget.sensorCollection][realTarget.sensorID] ?? [];
    return chunks[realTarget.sensorCollection][realTarget.sensorID];
  }

  const { graphFormatters, applyGraphFormatters } = await (async () => {
    const graphFormatters: {
      [key: string]: CooperativeGraphingValueFormatter
    } = {};

    const graphFormatterFields = ['options.legend.formatter', 'options.tooltip.x.formatter', 'options.xaxis.labels.formatter', 'options.yaxis.labels.formatter'];

    const createValueModifier: <TValue>(key: string, target: unknown) => [getter: () => TValue, setter: (newValue: TValue) => void, isValid: () => boolean] = <TValue>(key: string, target: any) => {
      const fullPath = key.split(/\.|\//);
      console.log(fullPath);
      const finalKey = fullPath[fullPath.length - 1];
      const container = (() => {
        let current = target;
        for (let i = 0; i < fullPath.length - 1; i++) {
          const targetKey = fullPath[i];
          const item = current[targetKey];
          if (item === undefined) {
            return undefined;
          }
          current = item;
        }
        return current;
      })();

      const isValid = () => container !== undefined;
      const getter = () => container?.[finalKey];
      const setter = (newValue: TValue) => {
        if (container !== undefined) {
          container[finalKey] = newValue;
        }
      }
      return [getter, setter, isValid] as any;
    };

    const applyGraphFormatters = (graph: GraphPackage) => {
      for (const key of graphFormatterFields) {
        const [get, set, isValid] = createValueModifier<string>(key, graph);
        if (isValid() && get() !== undefined) {
          const raw = get();
          const targetFormatters = JSON.parse(raw) as CooperativeGraphingPipeline;
          console.log(`Target formatters: `, targetFormatters);
          let resultMethod = (...args: any[]) => {
            return args;
          };
          for (const targetFormatter of targetFormatters) {
            let target: ((...args: any[]) => any[]) | undefined;
            const baseContext: CooperativeGraphingValueFormatterContextHydrated = {
              ...targetFormatter.context,
              targetFormatter: targetFormatter.target
            };
            for (const formatterKey in graphFormatters) {
              const formatter = graphFormatters[formatterKey];
              if (formatterKey !== targetFormatter.target) continue;
              target = formatter.contextualizer(baseContext);
              if (target !== undefined) break;
            }
            if (target === undefined) {
              // TODO: Some sort of error reporting
              console.warn(`Formatter '${targetFormatter}' does not exist. Skipping.`);
            }
            else {
              const old = resultMethod;
              resultMethod = (...args: any[]) => {
                return target!(...old(...args));
              };
            }
          }
          set(resultMethod as unknown as string);
        } else {
          console.log(`'${key}' not applied`);
        }
      }
    };

    return {
      graphFormatters,
      applyGraphFormatters
    }
  })();

  const getGlanceGraphForSensor = async (sensorCollection: string, sensorID: string) => {
    const client = await getMicroserviceCoreAPIClient();
    const microservice = await client.serviceDiscovery.getAvailableServiceInstanceForCapability([CONSTANTS.FEATURES.GENERIC]);
    const requestURL = urlJoin(microservice.rootURI, "sensor", sensorCollection, sensorID, "glanceGraph");
    const response = axios.get(requestURL.toString());
    const responseBody: GraphPackage[] = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
    responseBody.forEach(graph => {
      applyGraphFormatters(graph);
    });
    return responseBody;
  };

  return {
    graphFormatters,
    getDataSources,
    getSensorInstances,
    getBulkLatestSensorReadingDates,
    getDateOfLatestReading,
    getSensorData,
    getSensorPosition,
    getSensorStatusChunks,
    getAllSensorStatusChunks,
    getGlanceGraphForSensor
  };
}
