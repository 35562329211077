import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { CustomProvider } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

export type ThemerProps = {
  children: React.ReactNode;
};

export function Themer(props: ThemerProps) {
  return (
    <ThemeProvider theme={theme}>
      <CustomProvider theme="dark">
        <CssBaseline />
        {props.children}
      </CustomProvider>
    </ThemeProvider>
  );
}
