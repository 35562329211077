import { createOverrideableMethodManager } from "../../base-logic/OverridableMethod";

export type LoggerConfig = {
  sourceNamePath: string[]
};

export const LOGGING_WRITERS = createOverrideableMethodManager<{
  log: (config: LoggerConfig, message: unknown, data: unknown[]) => void,
  info: (config: LoggerConfig, message: unknown, data: unknown[]) => void,
  warn: (config: LoggerConfig, message: unknown, data: unknown[]) => void,
  error: (config: LoggerConfig, message: unknown, data: unknown[]) => void
}>();

for (const level of ['log', 'info', 'warn', 'error'] as const) {
  LOGGING_WRITERS.registerOverridableMethod(level, (config, message, data) => {
    if (data === undefined) {
      data = [];
    }
    console[level](`[${level.toUpperCase()}] [${config.sourceNamePath.join('::')}] ${message}`, ...data);
  });
}

export function createLogger(config: LoggerConfig) {
  const log = (message: unknown, ...data: unknown[]) => {
    LOGGING_WRITERS.getMethod('log')(config, message, data);
  };
  const info = (message: unknown, ...data: unknown[]) => {
    LOGGING_WRITERS.getMethod('info')(config, message, data);
  };
  const warn = (message: unknown, ...data: unknown[]) => {
    LOGGING_WRITERS.getMethod('warn')(config, message, data);
  };
  const error = (message: unknown, ...data: unknown[]) => {
    LOGGING_WRITERS.getMethod('error')(config, message, data);
  };

  const createSubLogger = (additionalPathNameComponents: string[]) => {
    return createLogger({
      sourceNamePath: [...config.sourceNamePath, ...additionalPathNameComponents]
    });
  };

  return {
    log,
    info,
    warn,
    error,
    createSubLogger
  };
}
