import ReactDOM from "react-dom/client";
import { App } from "./app";
import "./index.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import reportWebVitals from "./reportWebVitals";
import en from "javascript-time-ago/locale/en";
import TimeAgo from "javascript-time-ago";

(window as any).Apex = {
  chart: {
    foreColor: "#ccc",
    toolbar: {
      show: true,
    },
  },
  stroke: {
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    theme: 'dark',
  },
  grid: {
    borderColor: "#535A6C",
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
};

TimeAgo.addDefaultLocale(en);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
