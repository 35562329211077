import axios from "axios";
import { getMicroserviceCoreAPIClient } from "..";
import { MSCCommon } from "../../impl/shared";
import { urlJoin } from "../../workarounds/url-join";
import { SapAnalysisEntry, SapAnalysisParameter, SapAnalysisVarietal } from "../../types/sap-analysis";


export async function sapAnalysisServicePlugin() {
  const FEATURE_TAGS = {
    CORE: "sap-analysis"
  } as const;

  const getInstances = async (additionalFeatures?: string[]) => {
    const client = await getMicroserviceCoreAPIClient();
    const availableHandlers = await client.serviceDiscovery.getAvailableServicesForCapability([FEATURE_TAGS.CORE, ...additionalFeatures ?? []]);
    return availableHandlers;
  };

  const getInstance = async (additionalFeatures?: string[]) => {
    const instances = await getInstances(additionalFeatures);
    if (instances.length < 1) {
      throw new Error(`Failed to locate a service provider capable of fulfilling the task`);
    }
    return instances[0];
  }

  const isAvailable = async () => {
    return (await getInstances()).length > 0;
  };

  const getParameters = async () => {
    const instance = await getInstance();
    const requestURI = urlJoin(instance.rootURI, "parameters");
    const response = axios.get(requestURI.toString());
    const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
    return responseBody as SapAnalysisParameter[];
  };

  const getVarietals = async () => {
    const instance = await getInstance();
    const requestURI = urlJoin(instance.rootURI, "varietals");
    const response = axios.get(requestURI.toString());
    const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response);
    return responseBody as SapAnalysisVarietal[];
  };

  const getEntries = async () => {
    const instance = await getInstance();
    const requestURI = urlJoin(instance.rootURI, "entries");
    const response = axios.get(requestURI.toString());
    const responseBody = await MSCCommon.nestJS.getResponseOrThrowErrorAxios(response) as SapAnalysisEntry[];
    responseBody.forEach(x => {
      x.captureDate = new Date(x.captureDate);
    });
    return responseBody;
  };

  const insertEntry = async (entry: SapAnalysisEntry) => {
    const instance = await getInstance();
    const requestURI = urlJoin(instance.rootURI, "submit");
    const _ = await axios.post(requestURI.toString(), entry);
  };

  return {
    isAvailable,
    getParameters,
    getVarietals,
    getEntries,
    insertEntry
  }
}
