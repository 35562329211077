import {
  SensorInstance,
} from "@/features/microservice-core/types/sensorData";
import { create, StoreApi, UseBoundStore } from "zustand";
import { Context, createContext, useContext, useMemo } from "react";
import { GraphTraceConfiguration } from "@/app/pages/SensorData/components/graph-trace-configurator";
import { ClientTileset } from "@/features/microservice-core/api/types/geodata/Tileset";
import { DataSource, SensorStatusChunk, SensorStatusChunkMap } from "@/features/microservice-core/api/types/sensorData";

type UseContextStoreHookReturnType<HookType> =
  HookType extends UseBoundStore<StoreApi<infer StoreType>> ? StoreType : never;

const createContextUseHook = <HookType extends UseBoundStore<StoreApi<any>>>(
  context: Context<HookType>,
) => {
  return () => {
    const contextInstance = useContext(context);
    const value = contextInstance() as UseContextStoreHookReturnType<HookType>;
    return value;
  };
};

type NamedContextEcosystem<ContextType extends ReturnType<typeof createContextEcosystem<any>>, Name extends string> = {
  [ID in `use${Name}ContextStore`]: ContextType['useContext']
} & {
    [ID in `${Name}Context`]: ContextType['Context']
  } & {
    [ID in `${Name}ContextProvider`]: ContextType['ContextProvider']
  } & {
    [ID in `createStoreFor${Name}`]: ContextType['createStore']
  };

export function applyNamingToContextEcosystem<ContextType extends ReturnType<typeof createContextEcosystem<any>>, Name extends string>(input: ContextType, name: Name) {
  const result: NamedContextEcosystem<ContextType, Name> = {
    [`${name}Context`]: input.Context,
    [`use${name}ContextStore`]: input.useContext,
    [`${name}ContextProvider`]: input.ContextProvider,
    [`createStoreFor${name}`]: input.createStore,
  } as any; // Needs to be 'any' because TypeScript cannot infer the key type to be of type `keyof NamedContextEcosystem<ContextType, Name>`
  // and instead thinks it's of type 'string' (technically true, just not granular enough.
  // `as const` is also insufficient
  return result;
}

type ZustandBuilderSetterFunc<ContextType> = (op: Partial<ContextType> | ((old: ContextType) => Partial<ContextType>)) => void;
type ZustandBuilderGetterFunc<ContextType> = () => ContextType;
type ZustandBuilderType<ContextType> = (set: ZustandBuilderSetterFunc<ContextType>, get: ZustandBuilderGetterFunc<ContextType>) => ContextType;

// Creates a zustand store, react context, react context provider element, and a react context use hook
// Used for creating scoped state
export function createContextEcosystem<ContextType extends {}>(builder: ZustandBuilderType<ContextType>) {
  // Lambda for generating the Zustand store
  const createStore = () => {
    return create<ContextType>(builder);
  };
  // React context w/ default value
  const Context = createContext(createStore());
  // Creates a React hook for using the value of the context  
  const useContext = createContextUseHook(Context);
  // React element for creating an inner scoped context
  function ContextProvider({ children }: { children: any }) {
    const value = useMemo(() => {
      return createStore();
    }, []);

    return (
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    );
  }

  return { createStore, Context, useContext, ContextProvider };
}

export const { AvailableSensorsContext, AvailableSensorsContextProvider, useAvailableSensorsContextStore, } = applyNamingToContextEcosystem(createContextEcosystem<{
  availableSensors: SensorInstance[];
  setAvailableSensors: (instances: SensorInstance[]) => void;
}>((set) => ({
  availableSensors: [],
  setAvailableSensors: (instances) =>
    set({
      availableSensors: instances,
    }),
})), 'AvailableSensors');

export const { SelectedSensorsContext, SelectedSensorsContextProvider, useSelectedSensorsContextStore } = applyNamingToContextEcosystem(createContextEcosystem<{
  selectedSensors: SensorInstance[];
  setSelectedSensors: (instances: SensorInstance[]) => void;
}>((set) => ({
  selectedSensors: [],
  setSelectedSensors: (instances) =>
    set({
      selectedSensors: instances,
    }),
})), 'SelectedSensors')

export const { AvailableDataSourcesContextProvider, useAvailableDataSourcesContextStore, AvailableDataSourcesContext } = applyNamingToContextEcosystem(createContextEcosystem<{
  availableDataSources: DataSource[];
  setAvailableDataSources: (instances: DataSource[]) => void;
}>((set) => ({
  availableDataSources: [],
  setAvailableDataSources: (instances) =>
    set({
      availableDataSources: instances,
    }),
})), 'AvailableDataSources')

export const {
  createStoreForSelectedDateRangeStore,
  SelectedDateRangeStoreContext,
  SelectedDateRangeStoreContextProvider,
  useSelectedDateRangeStoreContextStore: useSelectedDateRangeStore
} = applyNamingToContextEcosystem(
  createContextEcosystem<{
    startDate: Date;
    endDate: Date;
    isRequestingChange: boolean
    requestChange: () => void,
    updateRange: (start: Date, end: Date) => void;
  }>((set) => ({
    isRequestingChange: false,
    startDate: new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7 * 4)), // One month
    endDate: new Date(),
    requestChange: () => set(({
      isRequestingChange: true
    })),
    updateRange: (start, end) => {
      // TODO: Maybe move this logic to a `reset()` method?
      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        // FIXME: Make the default 4 days ago start time configurable
        // NOTE: Fixing this requires the configuration service to come online
        start = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7 * 4));
        end = new Date();
      }
      // Make sure start and end are the start and end of their days, respecfully
      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(24, 59, 59, 999);
      set({ startDate: start, endDate: end, isRequestingChange: false });
    },
  })), 'SelectedDateRangeStore')

export const GraphTraceConfigurationInstancesContext = createContext<
  ReturnType<typeof createStoreForGraphTraceConfigurations>
>(createStoreForGraphTraceConfigurations());
export const useGraphTraceConfigurationInstancesStore = createContextUseHook(
  GraphTraceConfigurationInstancesContext,
);
export function GraphTraceConfigurationInstancesContextProvider({
  children,
}: {
  children: any;
}) {
  const value = useMemo(() => {
    return createStoreForGraphTraceConfigurations();
  }, []);

  return (
    <GraphTraceConfigurationInstancesContext.Provider value={value}>
      {children}
    </GraphTraceConfigurationInstancesContext.Provider>
  );
}
function createStoreForGraphTraceConfigurations() {
  return create<{
    instances: GraphTraceConfiguration[];
    modify: (
      action: (
        state: GraphTraceConfiguration[],
      ) => GraphTraceConfiguration[] | undefined,
    ) => void;
  }>((set) => ({
    instances: [],
    modify: (action) =>
      set((state) => ({
        instances: action(state.instances) || state.instances,
      })),
  }));
}



export const GlobalNamedCallbacksInstancesContext = createContext<ReturnType<typeof createStoreForGlobalNamedCallbacks>>(createStoreForGlobalNamedCallbacks());
export const useGlobalNamedCallbacksInstancesStore = createContextUseHook(GlobalNamedCallbacksInstancesContext);
export function GlobalNamedCallbacksInstancesContextProvider({
  children,
}: { children: any }) {
  const value = useMemo(() => {
    return createStoreForGlobalNamedCallbacks();
  }, []);
  return (
    <GlobalNamedCallbacksInstancesContext.Provider value={value}>
      {children}
    </GlobalNamedCallbacksInstancesContext.Provider>
  )
}

type GlobalCallback = {
  uniqueID: string,
  eventID: string,
  callback: (args: unknown[], target: string) => unknown
};
function createStoreForGlobalNamedCallbacks() {
  return create<{
    instances: GlobalCallback[],
    modify: (action: (
      state: GlobalCallback[]
    ) => GlobalCallback[] | undefined) => void
  }>((set) => ({
    instances: [],
    modify: action => set(state => ({
      instances: action(state.instances) || state.instances
    }))
  }))
}

export function useGlobalNamedCallbacksRegistration(callback: GlobalCallback) {
  const store = useGlobalNamedCallbacksInstancesStore();
  useMemo(() => {
    store.modify(state => [...state, callback]);
    return () => store.modify(state => state.filter(x => x.uniqueID !== callback.uniqueID));
  }, [callback.uniqueID]);
}

export function useGlobalNamedCallbacksInvoker() {
  const store = useGlobalNamedCallbacksInstancesStore();
  return (callbackID: string, args: unknown[], executionArgs: {
    mustFireAtLeastOnce?: boolean,
    absorbExceptions?: boolean
  }) => {
    const targets = store.instances.filter(x => x.eventID === callbackID);
    if (executionArgs.mustFireAtLeastOnce && targets.length === 0) {
      throw new Error(`Unable to locate an event handler for '${callbackID}'`);
    }
    let results: unknown[] = [];
    for (const target of targets) {
      try {
        results.push(target.callback(args, callbackID));
      }
      catch (e: unknown) {
        if (!executionArgs.absorbExceptions) {
          throw e;
        }
      }
    }
    return results;
  }
}

export type GraphPackageAppOptions = {
  flags: {
    TIMESTAMPS_ARE_NOT_PARSEABLE?: boolean
  }
};

export type GraphPackage = {
  options: ApexCharts.ApexOptions,
  series: ApexAxisChartSeries | ApexNonAxisChartSeries,
  appOptions: GraphPackageAppOptions
};



export const { GraphPackagesContext, GraphPackagesContextProvider, useGraphPackagesContextStore } = applyNamingToContextEcosystem(createContextEcosystem<{
  packages: GraphPackage[],
  modify: (mutator: (state: GraphPackage[]) => GraphPackage[] | undefined) => void
}>(set => ({
  packages: [],
  modify: mutator => set(state => ({
    packages: mutator(state.packages) || state.packages
  }))
})), 'GraphPackages');


export const { MapTilesetsContextProvider, MapTilesetsContext, useMapTilesetsContextStore } = applyNamingToContextEcosystem(createContextEcosystem<{
  tilesets: ClientTileset[],
  modify: (mutator: (old: ClientTileset[]) => ClientTileset[] | undefined) => void
}>(set => ({
  tilesets: [],
  modify: mutator => set(state => {
    const result = mutator(state.tilesets);
    if (result) {
      return {
        tilesets: result
      }
    }
    return {};
  })
})), 'MapTilesets')


export const { SensorStatusChunksContext, SensorStatusChunksContextProvider, useSensorStatusChunksContextStore } = applyNamingToContextEcosystem(createContextEcosystem<{
  data: SensorStatusChunkMap,
  modify: (mutator: (state: SensorStatusChunkMap) => SensorStatusChunkMap | undefined) => void,
  getStatus: (sensorCollection: string, sensorID: string) => SensorStatusChunk[]
}>((set, get) => ({
  data: {},
  modify: mutator => set(state => {
    const result = mutator(state.data);
    return { data: result } ?? state;
  }),
  getStatus: (sensorCollection, sensorID) => {
    const state = get().data;
    state[sensorCollection] = state[sensorCollection] ?? {};
    state[sensorCollection][sensorID] = state[sensorCollection][sensorID] ?? [];
    return state[sensorCollection][sensorID]!;
  }
})), 'SensorStatusChunks')


export const { DynamicPortalLayoutContextProvider, DynamicPortalLayoutContext, useDynamicPortalLayoutContextStore, createStoreForDynamicPortalLayout } = applyNamingToContextEcosystem(createContextEcosystem<{
  // Available render locations
  renderLocations: { key: string, portal: React.RefObject<HTMLElement> }[],
  // Represents locations currently being portaled into 
  viewKeysInUse: string[],
  // Registers a portal outlet location
  register: (key: string, portal: React.RefObject<HTMLElement>) => void,
  // Deregisters a portal outlet location
  unregister: (key: string) => void,
  // Notifiy the context that a view key is in use
  registerViewKey: (key: string) => void,
  // Notify the system that a view key is no longer in use
  unregisterViewKey: (key: string) => void,
}>(set => ({
  viewKeysInUse: [],
  renderLocations: [],
  register: (key, portal) => set(state => ({ renderLocations: [...state.renderLocations, { key, portal }] })),
  unregister: key => set(state => ({ renderLocations: state.renderLocations.filter(x => x.key !== key) })),
  registerViewKey: key => set(state => ({ viewKeysInUse: [...state.viewKeysInUse, key] })),
  unregisterViewKey: key => set(state => ({ viewKeysInUse: state.viewKeysInUse.filter(x => x !== key) }))
})), 'DynamicPortalLayout');
