import { authenticationServicePlugin } from "./authentication";
import { geoDataMicroservicePlugin } from "./geodata/geodataServiceClient";
import { sapAnalysisServicePlugin } from "./sap-analysis/sapAnalysisService";
import { sensorDataServicePlugin } from "./sensor-data/sensorDataService";
import { serviceDiscoveryMicroservicePlugin } from "./serviceDiscovery/serviceDiscovery";

const __mcAPIClientResult = (async () => {
  let result = {
    serviceDiscovery: await serviceDiscoveryMicroservicePlugin(),
    geodata: await geoDataMicroservicePlugin(),
    sensorData: await sensorDataServicePlugin(),
    authentication: await authenticationServicePlugin(),
    sapAnalysis: await sapAnalysisServicePlugin()
  };

  return result;
})();

/**
 * Fetches the microservice core API client.
 * DO NOT call this method during the initialization phase of the client-side microservice API setup. It will hang indefinitely if you do.
 **/
export async function getMicroserviceCoreAPIClient() {
  return await __mcAPIClientResult;
}
