// export const { useAvailableSapAnalysisParametersContextStore, createStoreForAvailableSapAnalysisParameters, AvailableSapAnalysisParametersContext, AvailableSapAnalysisParametersContextProvider } = applyNamingToContextEcosystem(createContextEcosystem<{
//   parameters: SapAnalysisParameter[],
//   isLoading: boolean,
//   setIsLoading: (value: boolean) => void,
//   setValues: (value: SapAnalysisParameter[]) => void
// }>(set => ({
//   parameters: [],
//   isLoading: false,
//   setIsLoading: value => set({ isLoading: value }),
//   setValues: value => set({ parameters: value })
// })), 'AvailableSapAnalysisParameters');

import { applyNamingToContextEcosystem, createContextEcosystem } from "@/providers";

export const { useDrawerStateContextStore, DrawerStateContextProvider } = applyNamingToContextEcosystem(createContextEcosystem<{
  isOpen: boolean,
  setIsOpen: (value: boolean) => void
}>(set => ({
  isOpen: false,
  setIsOpen: value => set({ isOpen: value })
})), 'DrawerState');
