import { AxiosError, AxiosResponse } from "axios";

export const MSCCommon = {
  nestJS: {
    _getResponseOrThrowError: async (response: Response, errorLeader?: string) => {
      if (response.ok) {
        return await response.json();
      }
      else {
        const errorBody = await response.json();
        const messageStart = `${errorLeader ?? ""}${errorLeader ? ": " : ""}`;
        throw new Error(`${messageStart}${errorBody.message}`);
      }
    },
    getResponseOrThrowErrorAxios: async (response: Promise<AxiosResponse<any, any>>, errorLeader?: string) => {
      try {
        const result = await response;
        return result.data;
      } catch (_e: unknown) {
        const error: AxiosError = _e as AxiosError;
        if (error.response === undefined) {
          throw new Error(`${errorLeader}: An unknown error occurred`);
        }
        else {
          const message = (error.response.data as { message: string }).message;
          throw new Error(`${errorLeader}: Server-provided error: ${message}`);
        }
      }
    }
  }
}
